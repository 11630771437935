import React, { useState } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { H1, H2, Paragraph, Heading } from 'components/common/Styles';
import { ICreator } from 'apiFolder/directus-main';

import Seo from 'components/seo';
import { ContentArticle820 } from 'components/Content';
import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';
import Footer from 'components/Footer/Footer';
import TypeFormComponent from 'components/common/TypeForm';
import ImageContainerComponent from 'components/common/image/ImageContainer';

interface ITeam {
	pageContext: {
		dataTeamContentCreators: ICreator[];
	};
}

const Team: React.FC<ITeam> = ({ pageContext: { dataTeamContentCreators } }) => {
	const [isMenuOpen, toggleMenu] = useState(false);
	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};

	return (
		<>
			<Seo
				title={`Team`}
				description='Find out why we created BookPhysio.com, an on-demand physiotherapy booking site that connects patients with experienced physiotherapists.'
			/>
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<ContentArticle820 isOpen={isMenuOpen}>
						<section>
							<TopPadding />
							<Paragraph />
							<H1>Meet the team that has your back, and whatever else hurts too.</H1>
							<Paragraph>
								At BookPhysio.com, we come to work every day to empower Australians to get back to
								feeling their best - no matter what they're dealing with.
							</Paragraph>
							<Paragraph>
								We thrive on a culture built on transparency and continuous improvement, where we can
								add value and support you.
							</Paragraph>
							<Paragraph>
								As a team, we are committed to building and nurturing inclusive teams at every level,
								and we seek out individuals who bring unique perspectives and experiences and an
								eagerness to embrace diversity, equity, and inclusion.
							</Paragraph>
							<Paragraph>
								<FoundersImage>
									<StaticImage src='../images/about-team.jpg' alt='team' width={820} height={545} />
								</FoundersImage>
							</Paragraph>
						</section>
						<section>
							<H2>Our core principles guide us on everything we build and strive to be.</H2>
							<Heading>Start and End with the Customer</Heading>
							<Paragraph>
								We create, build, and execute to improve the lives of our patients. At Physio Near Me,
								we put the hard work in, when no one is looking.
							</Paragraph>
							<Heading>Act from Ownership</Heading>
							<Paragraph>
								When we notice a problem or issue, we roll up our sleeves and fix it fast. We hold
								ourselves accountable because it’s our responsibility to take care of it.
							</Paragraph>
							<Heading>Build Openness</Heading>
							<Paragraph>
								We are open book, honest, and direct about problems, seek the truth, assume good
								intentions, and treat feedback as a valuable gift.
							</Paragraph>
							<Heading>1% Better Every Day</Heading>
							<Paragraph>
								We take a growth mindset approach and operate from a place of humility. We are energised
								by continually improving.
							</Paragraph>
							<Heading>Startup Mentality</Heading>
							<Paragraph>
								We move quickly, operate with urgency, and bias towards action without sacrificing any
								quality. We are relentlessly resourceful.
							</Paragraph>
							<Heading>One Team, One Dream</Heading>
							<Paragraph>
								Our power is our community that combines technology skills + operational excellence and
								talent - plus respect. We work through problems together and have genuine care for each
								other personally and professionally.
							</Paragraph>
							<Heading>Results Matter</Heading>
							<Paragraph>
								We have a clear focus on outputs and outcomes and hold ourselves accountable for hitting
								ambitious goals. We set ourselves a high bar and pay attention to the pixels, words,
								value and results.
							</Paragraph>
							<Heading>Celebrate Moments</Heading>
							<Paragraph>
								We work unforgivingly for our patients, clinics and teammates, so we take the time to
								celebrate moments large and small.
							</Paragraph>
						</section>
						<section>
							<H2>The Team</H2>
							<Row>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/team/matthew-g.jpg'
											alt={`Matthew Gregory - Co-founder & Head of Product`}
											width={160}
											height={160}
										/>
									</ImageBox>
									<Span>Matthew Gregory</Span>
									<SpanGrey>{`Co-founder & Head of Product`}</SpanGrey>
								</Cell>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/team/scott.jpg'
											alt={`Scott Gentle - Co-founder & Head of Operations`}
											width={160}
											height={160}
										/>
									</ImageBox>
									<Span>Scott Gentle</Span>
									<SpanGrey>{`Co-founder & Head of Operations`}</SpanGrey>
								</Cell>
							</Row>
						</section>
						<section>
							<Row>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/team/maddie-b.jpg'
											alt='Maddie'
											width={160}
											height={160}
										/>
									</ImageBox>
									<Span>Madeleine Boothe</Span>
									<SpanGrey>Legal Counsel</SpanGrey>
								</Cell>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/team/james-d.jpg'
											alt='James'
											width={160}
											height={160}
										/>
									</ImageBox>
									<Span>James Dutaillis</Span>
									<SpanGrey>Design Lead</SpanGrey>
								</Cell>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/team/anna-k.jpg'
											alt='Anna'
											width={160}
											height={160}
										/>
									</ImageBox>
									<Span>Anna</Span>
									<SpanGrey>Design Lead - UX</SpanGrey>
								</Cell>
								<Cell>
									<ImageBox>
										<StaticImage src='../images/team/dan.jpg' alt='Dan' width={160} height={160} />
									</ImageBox>
									<Span>Dan</Span>
									<SpanGrey>Development Lead - Engineering</SpanGrey>
								</Cell>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/team/ruslan.jpg'
											alt='Ruslan'
											width={160}
											height={160}
										/>
									</ImageBox>
									<Span>Ruslan</Span>
									<SpanGrey>Senior Back-End Engineer</SpanGrey>
								</Cell>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/team/yurii.jpg'
											alt='Yurii'
											width={160}
											height={160}
										/>
									</ImageBox>
									<Span>Yurii</Span>
									<SpanGrey>Senior Front-End Engineer</SpanGrey>
								</Cell>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/team/nicole.jpg'
											alt={`Nicole O'Brien`}
											width={160}
											height={160}
										/>
									</ImageBox>
									<Span>{`Nicole O'Brien`}</Span>
									<SpanGrey>Strategy Consultant</SpanGrey>
								</Cell>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/team/george-m.jpg'
											alt='George Masselos'
											width={160}
											height={160}
										/>
									</ImageBox>
									<Span>George Masselos</Span>
									<SpanGrey>Strategy Consultant</SpanGrey>
								</Cell>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/team/tegan.jpg'
											alt='Tegan Harlow'
											width={160}
											height={160}
										/>
									</ImageBox>
									<Span>Tegan Harlow</Span>
									<SpanGrey>Operations Manager</SpanGrey>
								</Cell>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/team/jane-o.jpg'
											alt='Jane Oja'
											width={160}
											height={160}
										/>
									</ImageBox>
									<Span>Jane Oja</Span>
									<SpanGrey>Head of Communication</SpanGrey>
								</Cell>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/team/majella.jpg'
											alt='Majella Campbell'
											width={160}
											height={160}
										/>
									</ImageBox>
									<Span>Majella Campbell</Span>
									<SpanGrey>{`Head of People & Culture`}</SpanGrey>
								</Cell>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/team/brett-c.jpg'
											alt='Brett Cruickshanks'
											width={160}
											height={160}
										/>
									</ImageBox>
									<Span>Brett Cruickshanks</Span>
									<SpanGrey>Marketing Consultant</SpanGrey>
								</Cell>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/team/charles-j.jpg'
											alt='Charles Jordan'
											width={160}
											height={160}
										/>
									</ImageBox>
									<Span>Charles Jordan</Span>
									<SpanGrey>Videographer</SpanGrey>
								</Cell>
							</Row>
						</section>
						<section>
							<H2>Editorial Team</H2>
							<Paragraph>
								Our editorial team is responsible for ensuring the content on BookPhysio.com meets the
								standards we set in our
								<HoveredInline>
									<Link to='/editorial-policy'>Editorial Policy</Link>.
								</HoveredInline>
							</Paragraph>
							<Paragraph>
								“For those of us working at BookPhysio.com, the topic of healthcare is something we’re
								all passionate about - which is what motivates us to build upon our library of health
								information, to help you get back to feeling your best - no matter what you're dealing
								with.”<Span>- Leon Mao, Senior Content Producer</Span>
							</Paragraph>
							<Row>
								{dataTeamContentCreators.map((creator) => (
									<Cell key={creator.slug}>
										<ImageBox>
											<ImageContainerComponent
												src={`${creator.profilePicture}?key=medium`}
												alt={creator.name}
												thumb={`${creator.profilePicture}?key=small`}
												isRound
											/>
										</ImageBox>
										<Link to={creator.slug}>
											<Span>{creator.name}</Span>
										</Link>
										<SpanGrey>Senior Contributor</SpanGrey>
										<SpanGrey>{`Qualified ${creator.medicalTitle}`}</SpanGrey>
									</Cell>
								))}
							</Row>
							{/* <Row>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/editorial-team/gina-arena.jpg'
											alt='Gina Arena'
											width={160}
											height={160}
										/>
									</ImageBox>
									<Link to='/medical-reviewer/gina-arena'>
										<Span>Gina Arena</Span>
									</Link>
									<SpanGrey>Senior Medical Reviewer</SpanGrey>{' '}
									<SpanGrey>PhD, Research Fellow</SpanGrey>{' '}
								</Cell>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/editorial-team/leon.jpg'
											alt='Leon Mao'
											width={160}
											height={160}
										/>
									</ImageBox>
									<Link to='/author/leon-mao'>
										<Span>Leon Mao</Span>
									</Link>
									<SpanGrey>Senior Content Producer</SpanGrey>
									<SpanGrey>Qualified Physiotherapist</SpanGrey>
								</Cell>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/team/jamie.jpg'
											alt='Jamie'
											width={160}
											height={160}
										/>
									</ImageBox>
									<Link to='/contributor/jamie-page'>
										<Span>Jamie Page</Span>
									</Link>
									<SpanGrey>Senior Contributor</SpanGrey>
									<SpanGrey>Qualified Physiotherapist</SpanGrey>
								</Cell>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/team/nikita.jpg'
											alt='Nikita'
											width={160}
											height={160}
										/>
									</ImageBox>
									<Link to='/contributor/nikita-mistry'>
										<Span>Nikita Mistry</Span>
									</Link>
									<SpanGrey>Senior Contributor</SpanGrey>
									<SpanGrey>Qualified Physiotherapist</SpanGrey>
								</Cell>
								<Cell>
									<ImageBox>
										<StaticImage
											src='../images/editorial-team/kelsy-weavil.jpg'
											alt='Dr Kelsy Weavil'
											width={160}
											height={160}
										/>
									</ImageBox>
									<Link to='/contributor/kelsy-weavil'>
										<Span>Dr Kelsy Weavil</Span>
									</Link>
									<SpanGrey>Senior Contributor</SpanGrey>
									<SpanGrey>Qualified Physiotherapist</SpanGrey>
								</Cell>
							</Row> */}
						</section>
						<section>
							<H2>Careers</H2>
							<Paragraph>
								We are always on the lookout for hard-working and talented (Engineers, UX, UI, Sales,
								Data) people to join our team.
							</Paragraph>
							<Paragraph>
								If you are passionate about challenging the status quo and want to improve the lives of
								our patients,{' '}
								<TypeFormComponent>
									<TextHovered>fill in our form</TextHovered>
								</TypeFormComponent>{' '}
								and tell us why you want to work with us.
							</Paragraph>
						</section>
					</ContentArticle820>
					<Footer />
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};

const TopPadding = styled.div`
	width: 100%;
	height: 50px;

	@media (max-width: 767px) {
		display: none;
	}
`;

const Row = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const Cell = styled.div`
	flex: 0 0 33.3%;
	display: flex;
	flex-direction: column;
	padding: 10px;
	margin-bottom: 30px;
	font-weight: 300;
	& a {
		color: #333e48;
		transition: all 0.3s ease;
		cursor: pointer;
		&:hover {
			color: ${({ theme }) => theme.palette.purp};
			text-decoration: none;
		}
	}
	@media (max-width: 767px) {
		flex: 0 0 50%;
	}
	@media (max-width: 767px) {
		flex: 0 0 50%;
	}
	@media (max-width: 375px) {
		flex: 0 0 100%;
	}
`;

const Hovered = styled(Paragraph)`
	& a {
		color: ${({ theme }) => theme.palette.purp};
		transition: all 0.3s ease;
		cursor: pointer;
		&:hover {
			color: ${({ theme }) => theme.palette.main};
			text-decoration: none;
		}
	}
`;
const TextHovered = styled.div`
	font-weight: 300;
	color: #424bec;
	text-decoration: underline;
	transition: all 0.3s ease;
	cursor: pointer;
	&:hover {
		color: #7c7c7c;
		text-decoration: none;
	}
`;
const HoveredInline = styled(Hovered)`
	display: inline-block;
	margin: 0 5px;
	&:first-child {
		margin: 0 5px;
	}
`;

const FoundersImage = styled.div`
	& img {
		border-radius: 6px;
	}
`;
const ImageBox = styled.div`
	& img {
		border-radius: 50%;
		max-width: 160px;
	}
`;

const Span = styled.span`
	display: block;
	font-weight: 600;
	margin-top: 20px;
`;

const SpanGrey = styled(Span)`
	font-size: 16px;
	font-weight: 300;
	color: #7c7c7c;
	margin-top: 0px;
	text-lign: center;
	margin-top: 10px;
`;

export default Team;
